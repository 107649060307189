import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../../Layout"
import Seo from "../../../components/Seo/Seo"
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import PageBannerStyle from "../../../components/PageBannerStyle/PageBannerStyle";
import TitlePage from '../../../Molecules/TitlePage/TitlePage'
import TitleSection from '../../../Molecules/TitleSection/TitleSection'
import Dropdown from '../../../components/Dropdown/Dropdown'
import ListAdminMembers from "../../../components/ListAdminMembers/ListAdminMembers";

import BImage from '../../../assets/images/varlorcol_desktop.png'


import './styles.scss'

var classNames = require('classnames');


const AnalystMeetingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "single-article-visu-desktop.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const list_member_direction = [
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    },
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    },
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    }
  ]

  return (
    <Layout>
      <Seo
        title="Colas - Réunion des analystes"
        description="Description de la page Réunion des analystes"
      />
      <div className={classNames('page_template', 'comite_direction_page')}>
        <PageBannerStyle
          visuel={data.file.childImageSharp.fluid} scroll={true}
          breadcrumbData={{
            grandParentPage: { title: "Acceuil", url: "/" },
            parentPage: { title: "Finance", url: "/" },
            currentPage: { title: "Réunion des analystes", url: "/finance/analyst-meeting" }
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title="Comité direction"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in efficitur ante. Duis consequat ex at urna iaculis, id malesuada erat pretium. Morbi facilisis, nunc vitae luctus dignissim, neque augue dignissim nisl, a dictum est nunc at quam. Duis placerat mi a nisi faucibus vulputate. Nulla quis auctor sapien.
            </p>
          </div>
        </PageBannerStyle>
        <section className="section_content section_comite_direction">
          <div className="wrapper_page_xs">
            <TitleSection
              title="Les membres du comité de direction"
              description="Le comité de direction est composé que 15 membres :"
            />
          </div>
          <div className="wrapper_page">
            <ListAdminMembers
              listAdmins={list_member_direction}
            />
          </div>
        </section>

      </div>
    </Layout>
  )

}

export default AnalystMeetingPage
